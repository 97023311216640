<template>
    <div class="admin_layout">

        <!--좌측 탭 메뉴-->
        <div class="admin_layout_menu">
            <div class="admin_layout_menu_img_box">
                <img class="admin_layout_menu_img" src="@/assets/images/admin_logo_white.png">
            </div>

            <div class="admin_layout_menu_item_box" style="background-color: #9C836A;">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/doorlock_list_icon.svg">
                    <span class="admin_layout_menu_item_span"> 도어락 리스트</span>
                </div>
            </div>

            <div @click="register" class="admin_layout_menu_item_box" style="cursor: pointer">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/register_icon.svg">
                    <span class="admin_layout_menu_item_span"> 도어락 등록</span>
                </div>
            </div>

            <div @click="key_issue_list" class="admin_layout_menu_item_box" style="cursor: pointer">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/key_issue_icon.svg">
                    <span class="admin_layout_menu_item_span"> 모바일 키 발급이력</span>
                </div>
            </div>

            <div @click="key_usage_list" class="admin_layout_menu_item_box" style="cursor: pointer">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/key_usage_icon.svg">
                    <span class="admin_layout_menu_item_span"> 이용현황 리스트</span>
                </div>
            </div>
        </div>


        <!--컨텐츠 박스-->
        <div class="admin_layout_content_box">

            <div class="admin_layout_content">

                <div class="admin_layout_logout_box">
                    <div @click="logout" class="admin_layout_logout_button">로그아웃</div>
                </div>

                <div class="admin_layout_title_box">
                    도어락 리스트
                </div>


                <div class="admin_layout_doorlock_filter_box">

                    <div class="admin_layout_doorlock_filter_type" style="margin-left: 0px;">
                        <span class="admin_layout_doorlock_filter_type_text">도어락 타입</span>
                    </div>
                    <div class="admin_layout_doorlock_filter_select">
                        <div class="admin-select" style="width:160px;">
                            <select id="doorlock_type">
                                <option value="all">전체</option>
                                <option value="room">객실</option>
                                <option value="public">공용도어</option>
                            </select>
                        </div>
                    </div>


                    <div class="admin_layout_doorlock_filter_type">
                        <span class="admin_layout_doorlock_filter_type_text">빌딩 타입</span>
                    </div>
                    <div class="admin_layout_doorlock_filter_select">
                        <div class="admin-select" style="width:160px;">
                            <select id="building_type">
                                <option value="all">전체</option>
                                <option value="org">본관</option>
                                <option value="new">신관</option>
                            </select>
                        </div>
                    </div>


                    <div class="admin_layout_doorlock_filter_type">
                        <span class="admin_layout_doorlock_filter_type_text">층 수</span>
                    </div>
                    <div class="admin_layout_doorlock_filter_select">
                        <div class="admin-select" style="width:160px;">
                            <select id="floor_type">
                                <option value="all">전체</option>
                                <option value="5">5층</option>
                                <option value="6">6층</option>
                                <option value="7">7층</option>
                                <option value="8">8층</option>
                                <option value="9">9층</option>
                                <option value="10">10층</option>
                                <option value="11">11층</option>
                                <option value="12">12층</option>
                                <option value="14">14층</option>
                                <option value="15">15층</option>
                                <option value="16">16층</option>
                                <option value="17">17층</option>
                                <option value="18">18층</option>
                            </select>
                        </div>
                    </div>

                    <div class="admin_layout_doorlock_filter_type">
                        <span class="admin_layout_doorlock_filter_type_text">객실 번호</span>
                    </div>
                    <div class="admin_layout_doorlock_filter_input_box">
                        <input class="admin_layout_doorlock_filter_input_number" type="number" v-model="rmno" placeholder="ex. 501">
                    </div>

                    <div @click="get_doorlock"  class="admin_layout_doorlock_filter_search_box">
                        <img style="padding: 12px 10px;" src="@/assets/icon/admin_search.svg">
                    </div>


                </div>

                <div style="width: 100%;">

                    <div v-if="doorlock_count === 0 ">
                        <div style="display:flex; justify-content:center; width: 100%; height: 500px; line-height: 500px;font-family: AppleM; font-size: 16px; color: grey">
                            * 해당 도어락이 존재하지 않습니다.
                        </div>
                    </div>

                    <div v-else>

                        <!--공용도어-->
                        <div v-if="public_list.length!==0" class="admin_layout_doorlock_room_title">공용 라운지</div>
                        <div v-if="public_list.length!==0" class="admin_layout_doorlock_room_item_box" >
                            <div @click="doorlock_info(item.BM_RMNO)" class="admin_layout_doorlock_room_item_guest"  v-for="item in public_list" :key="item" style="background-color: #4D4843;" >
                                <div class="admin_layout_doorlock_room_item_guest_rmno">
                                    {{item.BM_RMNO}}
                                </div>
                                <div class="admin_layout_doorlock_room_item_guest_gstno">

                                </div>
                                <div class="admin_layout_doorlock_room_item_guest_systus" style="color:  #4D4843;">
                                    이용가능
                                </div>
                            </div>

                        </div>

                        <!--본관-->
                        <div v-if="room_list_org5.length!==0" class="admin_layout_doorlock_room_title">본관 5층</div>
                        <div v-if="room_list_org5.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org5" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">

                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="room_list_org6.length!==0" class="admin_layout_doorlock_room_title">본관 6층</div>
                        <div v-if="room_list_org6.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org6" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="room_list_org7.length!==0" class="admin_layout_doorlock_room_title">본관 7층</div>
                        <div v-if="room_list_org7.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org7" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_org8.length!==0" class="admin_layout_doorlock_room_title">본관 8층</div>
                        <div v-if="room_list_org8.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org8" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_org9.length!==0" class="admin_layout_doorlock_room_title">본관 9층</div>
                        <div v-if="room_list_org9.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org9" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_org10.length!==0" class="admin_layout_doorlock_room_title">본관 10층</div>
                        <div v-if="room_list_org10.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org10" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_org11.length!==0" class="admin_layout_doorlock_room_title">본관 11층</div>
                        <div v-if="room_list_org11.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org11" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_org12.length!==0" class="admin_layout_doorlock_room_title">본관 12층</div>
                        <div v-if="room_list_org12.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org12" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_org14.length!==0" class="admin_layout_doorlock_room_title">본관 14층</div>
                        <div v-if="room_list_org14.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org14" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_org15.length!==0" class="admin_layout_doorlock_room_title">본관 15층</div>
                        <div v-if="room_list_org15.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org15" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_org16.length!==0" class="admin_layout_doorlock_room_title">본관 16층</div>
                        <div v-if="room_list_org16.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_org16" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>



                        <!--신관-->
                        <div v-if="room_list_new5.length!==0" class="admin_layout_doorlock_room_title">신관 5층</div>
                        <div v-if="room_list_new5.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new5" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new6.length!==0" class="admin_layout_doorlock_room_title">신관 6층</div>
                        <div v-if="room_list_new6.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new6" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new7.length!==0" class="admin_layout_doorlock_room_title">신관 7층</div>
                        <div v-if="room_list_new7.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new7" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new8.length!==0" class="admin_layout_doorlock_room_title">신관 8층</div>
                        <div v-if="room_list_new8.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new8" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new9.length!==0" class="admin_layout_doorlock_room_title">신관 9층</div>
                        <div v-if="room_list_new9.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new9" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new10.length!==0" class="admin_layout_doorlock_room_title">신관 10층</div>
                        <div v-if="room_list_new10.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new10" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new11.length!==0" class="admin_layout_doorlock_room_title">신관 11층</div>
                        <div v-if="room_list_new11.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new11" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new12.length!==0" class="admin_layout_doorlock_room_title">신관 12층</div>
                        <div v-if="room_list_new12.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new12" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new14.length!==0" class="admin_layout_doorlock_room_title">신관 14층</div>
                        <div v-if="room_list_new14.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new14" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new15.length!==0" class="admin_layout_doorlock_room_title">신관 15층</div>
                        <div v-if="room_list_new15.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new15" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new16.length!==0" class="admin_layout_doorlock_room_title">신관 16층</div>
                        <div v-if="room_list_new16.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new16" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new17.length!==0" class="admin_layout_doorlock_room_title">신관 17층</div>
                        <div v-if="room_list_new17.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new17" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div v-if="room_list_new18.length!==0" class="admin_layout_doorlock_room_title">신관 18층</div>
                        <div v-if="room_list_new18.length!==0" class="admin_layout_doorlock_room_item_box">
                            <div @click="doorlock_info(item.BM_RMNO)" v-for="item in room_list_new18" :key="item" style="display: inline-block;">

                                <div v-if="item.BM_DEPARTTIME !=null && item.BM_DEPARTTIME >= now_time" class="admin_layout_doorlock_room_item_guest">
                                    <div class="admin_layout_doorlock_room_item_guest_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_gstno">
                                        {{item.BM_GSTNO}}
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_guest_systus">
                                        발급 완료
                                    </div>
                                </div>
                                <div v-else class="admin_layout_doorlock_room_item_empty">
                                    <div class="admin_layout_doorlock_room_item_empty_rmno">
                                        {{item.BM_RMNO}}호
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_gstno">
                                    </div>
                                    <div class="admin_layout_doorlock_room_item_empty_systus">
                                        발급 전
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                //도어락 리스트 필터
                doorlock_type: '',
                building_type: '',
                floor_type: '',
                rmno : '',
                //도어락 개수
                doorlock_count : 0,
                now_time : null,
                //자동 새로고침
                auto_reload : false,
                auto_reload_func : null,
                //공용 도어
                public_list :[],
                //본관 객실
                room_list_org5 :[],
                room_list_org6 :[],
                room_list_org7 :[],
                room_list_org8 :[],
                room_list_org9 :[],
                room_list_org10 :[],
                room_list_org11 :[],
                room_list_org12 :[],
                room_list_org14 :[],
                room_list_org15 :[],
                room_list_org16 :[],
                //신관 객실
                room_list_new5 :[],
                room_list_new6 :[],
                room_list_new7 :[],
                room_list_new8 :[],
                room_list_new9 :[],
                room_list_new10 :[],
                room_list_new11 :[],
                room_list_new12 :[],
                room_list_new14 :[],
                room_list_new15 :[],
                room_list_new16 :[],
                room_list_new17 :[],
                room_list_new18 :[],
            }
        },
        async created() {

            // //로그인 세션 검증
            // if(this.$session.get('admin_session') === '' || !this.$session.get('admin_session') ){
            //     alert('로그인 세션이 만료되었습니다. 다시 한번 로그인해주세요');
            //     this.$router.replace('/admin/login');
            // }


          // 로그인 세션 검증
          const adminSession = sessionStorage.getItem('admin_session');
          const expirationTime = sessionStorage.getItem('expiration_time');
          const now = new Date().getTime();

          // 세션이 없거나, 만료 시간이 지난 경우
          if (!adminSession || now > expirationTime) {
            alert('로그인 세션이 만료되었습니다. 다시 한번 로그인해주세요.');
            sessionStorage.removeItem('admin_session'); // 세션 제거
            sessionStorage.removeItem('expiration_time'); // 만료 시간 제거
            this.$router.replace('/admin/login'); // 로그인 페이지로 리다이렉트
          } else {
            // 세션이 유효할 경우, 남은 시간 계산
            const remainingTime = Math.max(0, (expirationTime - now) / 1000); // 남은 시간(초)
            console.log(`세션 만료까지 남은 시간: ${remainingTime}초`);
          }


            //초기 도어락 리스트 필터 세팅
            this.doorlock_type='all';
            this.building_type='all';
            this.floor_type='all';
            this.rmno='';

        },

        async mounted() {
            //도어락 데이터 불러오기 (자동 갱신)
            this.start_auto_reload();
            //selectjs 함수 호출
            this.select_box_js();
        },
        async unmounted(){
            //도어락 데이터 불러오기 중단
            this.stop_auto_reload()
        },
        methods: {

            //도어락 리스트 가져오기
            async get_doorlock() {

                var doorlock_select = document.getElementById("doorlock_type");
                var building_select = document.getElementById("building_type");
                var floor_select = document.getElementById("floor_type");

                this.doorlock_type= doorlock_select.options[document.getElementById("doorlock_type").selectedIndex].value;
                this.building_type = building_select.options[document.getElementById("building_type").selectedIndex].value;
                this.floor_type= floor_select.options[document.getElementById("floor_type").selectedIndex].value;


                /**************** 시간 설정 **********/
                var date = new Date();
                var year = date.getFullYear();
                var month = ("0" + (1 + date.getMonth())).slice(-2);
                var day = ("0" + date.getDate()).slice(-2);
                var hour = ("0" + date.getHours()).slice(-2);
                var minute = ("0" + date.getMinutes()).slice(-2);
                var second = ("0" + date.getSeconds()).slice(-2);
                var NOW_TIME = year + month + day + hour + minute + second;

                this.now_time = NOW_TIME;

                try{

                    const res = await this.axios({
                        method: "POST",
                        url: `${process.env.VUE_APP_API_SERVER}/api/admin/doorlock_list`,
                        headers: {
                            "Content-Type": "application/json",
                            // 'Authorization': `Bearer ${this.$session.get('admin_session')}`
                            'Authorization': `Bearer ${sessionStorage.getItem('admin_session')}`
                        },
                        data: {
                            doorlock_type: this.doorlock_type,
                            building_type: this.building_type,
                            floor_type: this.floor_type,
                            rmno : this.rmno
                        }
                    });

                    const data = await res.data;

                    if(data.status === "SUCCESS"){


                        this.public_list=[];
                        this.room_list_org5=[];
                        this.room_list_org6=[];
                        this.room_list_org7=[];
                        this.room_list_org8=[];
                        this.room_list_org9=[];
                        this.room_list_org10=[];
                        this.room_list_org11=[];
                        this.room_list_org12=[];
                        this.room_list_org14=[];
                        this.room_list_org15=[];
                        this.room_list_org16=[];

                        this.room_list_new5=[];
                        this.room_list_new6=[];
                        this.room_list_new7=[];
                        this.room_list_new8=[];
                        this.room_list_new9=[];
                        this.room_list_new10=[];
                        this.room_list_new11=[];
                        this.room_list_new12=[];
                        this.room_list_new14=[];
                        this.room_list_new15=[];
                        this.room_list_new16=[];
                        this.room_list_new17=[];
                        this.room_list_new18=[];



                        if(data.result.length==0){ // 도어락이 존재하지 않을 경우

                            this.doorlock_count = 0;

                        }else{

                            this.doorlock_count = data.result.length;

                            for (var i = 0; i < data.result.length; i++) {

                                if(data.result[i].BM_DOORLOCK=="PUBLIC"){ // 공용도어
                                    this.public_list.push(data.result[i]);
                                }else { // 객실

                                    if (data.result[i].BM_BUILDING == "ORG") {//본관
                                        if (data.result[i].BM_FLOOR == "5") {
                                            this.room_list_org5.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "6") {
                                            this.room_list_org6.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "7") {
                                            this.room_list_org7.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "8") {
                                            this.room_list_org8.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "9") {
                                            this.room_list_org9.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "10") {
                                            this.room_list_org10.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "11") {
                                            this.room_list_org11.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "12") {
                                            this.room_list_org12.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "14") {
                                            this.room_list_org14.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "15") {
                                            this.room_list_org15.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "16") {
                                            this.room_list_org16.push(data.result[i]);
                                        }

                                    } else { //신관
                                        if (data.result[i].BM_FLOOR == "5") {
                                            this.room_list_new5.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "6") {
                                            this.room_list_new6.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "7") {
                                            this.room_list_new7.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "8") {
                                            this.room_list_new8.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "9") {
                                            this.room_list_new9.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "10") {
                                            this.room_list_new10.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "11") {
                                            this.room_list_new11.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "12") {
                                            this.room_list_new12.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "14") {
                                            this.room_list_new14.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "15") {
                                            this.room_list_new15.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "16") {
                                            this.room_list_new16.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "17") {
                                            this.room_list_new17.push(data.result[i]);
                                        } else if (data.result[i].BM_FLOOR == "18") {
                                            this.room_list_new18.push(data.result[i]);
                                        }
                                    }

                                }

                            }

                        }


                    }else{
                        alert('도어락 리스트 조회에 실패했습니다.\n잠시 후에 다시 시도해주세요')
                    }


                }catch (e) {
                    console.log(e);
                }
            },


            //도어락 상세정보 이동하기
            doorlock_info(BM_RMNO) {
                this.$router.push('/admin/doorlock_info?BM_RMNO='+BM_RMNO);
            },

            //도어락 리스트 데이터 호출하기 (자동 갱신)
            async start_auto_reload() {
                this.get_doorlock();
                this.auto_reload = true;
                //도어락 데이터 호출 (5초 간격)
                this.auto_reload_func = setInterval(() => {
                    this.get_doorlock();
                }, 5000)
            },

            //도어락 리스트 데이터 호출 중단
            stop_auto_reload() {
                this.auto_reload = false;
                clearInterval(this.auto_reload_func);
            },

            //페이지 이동 함수
            register() {
                this.$router.push('/admin/register');
            },
            key_issue_list() {
                this.$router.push('/admin/key_issue_list');
            },
            key_usage_list() {
                this.$router.push('/admin/key_usage_list');
            },

            //로그아웃하기
            logout() {
                var logout_confirm = confirm("정말로 로그아웃 하시겠습니까?");
                if(logout_confirm == true){
                    //토큰, 세션 삭제 후 로그인 페이지로 이동
                    // this.$session.set('admin_session', '');
                    sessionStorage.removeItem('admin_session'); // 세션 제거
                    sessionStorage.removeItem('expiration_time'); // 만료 시간 제거
                    this.$router.replace('/admin/login');
                }
            },

            //selectjs 함수
            select_box_js() {
                var x, i, j, l, ll, selElmnt, a, b, c;
                /* Look for any elements with the class "admin-select": */
                x = document.getElementsByClassName("admin-select");
                l = x.length;
                for (i = 0; i < l; i++) {
                    selElmnt = x[i].getElementsByTagName("select")[0];
                    ll = selElmnt.length;
                    /* For each element, create a new DIV that will act as the selected item: */
                    if (document.getElementsByClassName("admin-select-selected")[i]) {
                        document.getElementsByClassName("admin-select-selected")[i].remove();
                        document.getElementsByClassName("admin-select-items admin-select-hide")[i].remove();
                    }
                    a = document.createElement("DIV");
                    a.setAttribute("class", "admin-select-selected");
                    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
                    x[i].appendChild(a);
                    /* For each element, create a new DIV that will contain the option list: */
                    b = document.createElement("DIV");
                    b.setAttribute("class", "admin-select-items admin-select-hide");
                    for (j = 0; j < ll; j++) {
                        /* For each option in the original select element,
                        create a new DIV that will act as an option item: */
                        c = document.createElement("DIV");
                        c.innerHTML = selElmnt.options[j].innerHTML;
                        c.addEventListener("click", function (e) {
                            /* When an item is clicked, update the original select box,
                            and the selected item: */
                            e.stopPropagation();
                            var y, i, k, s, h, sl, yl;
                            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                            sl = s.length;
                            h = this.parentNode.previousSibling;
                            for (i = 0; i < sl; i++) {
                                if (s.options[i].innerHTML == this.innerHTML) {
                                    s.selectedIndex = i;
                                    h.innerHTML = this.innerHTML;
                                    y = this.parentNode.getElementsByClassName("same-as-selected");
                                    yl = y.length;
                                    for (k = 0; k < yl; k++) {
                                        y[k].removeAttribute("class");
                                    }
                                    this.setAttribute("class", "same-as-selected");
                                    break;
                                }
                            }
                            h.click();
                        });
                        b.appendChild(c);
                    }
                    x[i].appendChild(b);

                    a.addEventListener("click", function (e) {
                        //console.log('클릭');
                        /* When the select box is clicked, close any other select boxes,
                        and open/close the current select box: */
                        e.stopPropagation();
                        closeAllSelect(this);
                        this.nextSibling.classList.toggle("admin-select-hide");
                        this.classList.toggle("select-arrow-active");
                    });
                }

                function closeAllSelect(elmnt) {
                    /* A function that will close all select boxes in the document,
                    except the current select box: */
                    var x, y, i, xl, yl, arrNo = [];
                    x = document.getElementsByClassName("admin-select-items");
                    y = document.getElementsByClassName("admin-select-selected");
                    xl = x.length;
                    yl = y.length;
                    for (i = 0; i < yl; i++) {
                        if (elmnt == y[i]) {
                            arrNo.push(i)
                        } else {
                            y[i].classList.remove("select-arrow-active");
                        }
                    }
                    for (i = 0; i < xl; i++) {
                        if (arrNo.indexOf(i)) {
                            x[i].classList.add("admin-select-hide");
                        }
                    }
                }
                /* If the user clicks anywhere outside the select box,
                then close all select boxes: */
                document.addEventListener("click", closeAllSelect);
            }
        }

    }
</script>


<style>
    .admin_layout_doorlock_filter_box {display: flex;margin-top: 40px;width: 100%;height: 90px;border-radius: 6px;}
    .admin_layout_doorlock_filter_type {display: flex;justify-content: center;margin: 0px 0px 20px 28px;width: 135px;height: 52px;background-color: #EDEDED; border: 1px solid #D8D8D8;  color: #9C836A;border-radius: 6px 0px 0px 6px;}
    .admin_layout_doorlock_filter_type_text {line-height: 50px;font-size: 16px;font-family: AppleM;}
    .admin_layout_doorlock_filter_select {width: 162px;height: 52px;background-color: white; border-top: 1px solid #D8D8D8; border-bottom: 1px solid #D8D8D8; border-right: 1px solid #D8D8D8; border-radius: 0px 6px 6px 0px;}
    .admin_layout_doorlock_filter_input_box {width: 162px;height: 52px;background-color: white; border-top: 1px solid #D8D8D8; border-bottom: 1px solid #D8D8D8; border-right: 1px solid #D8D8D8; border-radius: 0px 6px 6px 0px;}
    .admin_layout_doorlock_filter_input_number {width: 100%;height: 48px;line-height: 48px;padding: 5px 15px;font-size: 16px;font-family: AppleM;border: none;outline: none;border-radius: 0px 6px 6px 0px;}
    .admin_layout_doorlock_filter_search_box {display: flex;justify-content: center;margin: 0px 0px 20px 28px;width: 70px;height: 50px;background-color: #9C836A;border-radius: 6px;cursor: pointer}
    .admin-select-selected {background-color: white;border-radius: 0px 6px 6px 0px;}
    .admin_layout_doorlock_room_title{margin-top: 40px;  font-family: AppleB; font-size: 20px;}
    .admin_layout_doorlock_room_item_box{display:inline-block;  width: 100%;}
    .admin_layout_doorlock_room_item_lounge{display:inline-block;margin-top: 20px;margin-right: 20px; width: 120px; height: 120px; background-color: #4D4843; border-radius: 6px;}
    .admin_layout_doorlock_room_item_guest{display:inline-block;margin-top: 20px;margin-right: 20px; width: 120px; height: 120px; background-color: #9C836A; border-radius: 6px; cursor:pointer;}
    .admin_layout_doorlock_room_item_guest_rmno{display:flex; justify-content:center; width: 120px; height: 42px; line-height: 55px; font-size: 16px; font-family: AppleB; color: white;}
    .admin_layout_doorlock_room_item_guest_gstno{display:flex; justify-content:center;width: 120px; height: 33px;line-height: 35px; font-size: 14px; font-family: AppleR; color: white;}
    .admin_layout_doorlock_room_item_guest_systus{display:flex; justify-content:center; margin:10px 20px; width: 80px; height: 25px; line-height:25px; background-color: white;border-radius: 6px;color: #9C836A;font-size: 12px; font-family: AppleM;}
    .admin_layout_doorlock_room_item_empty{display:inline-block;margin-top: 20px;margin-right: 20px; width: 120px; height: 120px; background-color: white; border: 1px solid  #EDEDED; border-radius: 6px;cursor:pointer;}
    .admin_layout_doorlock_room_item_empty_rmno{display:flex; justify-content:center; width: 120px; height: 42px; line-height: 55px; font-size: 16px; font-family: AppleB; color: #9C836A;}
    .admin_layout_doorlock_room_item_empty_gstno{display:flex; justify-content:center;width: 120px; height: 33px;line-height: 35px; font-size: 14px; font-family: AppleR; color: white;}
    .admin_layout_doorlock_room_item_empty_systus{display:flex; justify-content:center; margin:10px 20px; width: 80px; height: 25px; line-height:25px; background-color: #EDEDED;border-radius: 6px;color: #9C836A;font-size: 12px; font-family: AppleM;}
</style>

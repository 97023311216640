<template>
    <div class="admin_layout">

        <!--좌측 탭 메뉴-->
        <div class="admin_layout_menu">
            <div class="admin_layout_menu_img_box">
                <img class="admin_layout_menu_img" src="@/assets/images/admin_logo_white.png">
            </div>

            <div @click="doorlock_list" class="admin_layout_menu_item_box" style="background-color: #9C836A;cursor: pointer">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/doorlock_list_icon.svg">
                    <span class="admin_layout_menu_item_span"> 도어락 리스트</span>
                </div>
            </div>

            <div @click="register" class="admin_layout_menu_item_box" style="cursor: pointer">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/register_icon.svg">
                    <span class="admin_layout_menu_item_span"> 도어락 등록</span>
                </div>
            </div>

            <div @click="key_issue_list" class="admin_layout_menu_item_box" style="cursor: pointer">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/key_issue_icon.svg">
                    <span class="admin_layout_menu_item_span" > 모바일 키 발급이력</span>
                </div>
            </div>

            <div @click="key_usage_list" class="admin_layout_menu_item_box" style="cursor: pointer">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/key_usage_icon.svg">
                    <span class="admin_layout_menu_item_span" > 이용현황 리스트</span>
                </div>
            </div>
        </div>



        <!--컨텐츠 박스-->
        <div class="admin_layout_content_box">

            <div class="admin_layout_content">

                <div class="admin_layout_logout_box">
                    <div @click="logout" class="admin_layout_logout_button">로그아웃</div>
                </div>

                <div class="admin_layout_title_box">
                    도어락 상세정보1
                </div>


                <div class="admin_layout_detail_box">

                    <div class="admin_layout_detail_title_box">일반 정보</div>

                    <div class="admin_layout_detail_info_box">
                        <div class="admin_layout_detail_info_title" style="border: 1px solid #D8D8D8;">
                            객실 번호
                        </div>
                        <div class="admin_layout_detail_info_input">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="rmno_data" readonly>
                        </div>


                        <div class="admin_layout_detail_info_title">
                            도어락 타입
                        </div>
                        <div class="admin_layout_detail_info_select">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="doorlock_data" readonly>
                        </div>
                        <div class="admin_layout_detail_info_title">
                            빌딩 타입
                        </div>
                        <div class="admin_layout_detail_info_select">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="building_data" readonly>
                        </div>
                        <div class="admin_layout_detail_info_title">
                            층 수
                        </div>
                        <div class="admin_layout_detail_info_select">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="floor_data" readonly>
                        </div>
                    </div>



                    <div v-if="rmno_data != 'P-LOUNGE'" class="admin_layout_detail_title_box" style="margin-top:50px;">투숙객 정보</div>

                    <div v-if="rmno_data != 'P-LOUNGE'" class="admin_layout_detail_info_box">
                        <div class="admin_layout_detail_info_title" style="border-left: 1px solid #D8D8D8;">
                            숙소 예약번호
                        </div>
                        <div class="admin_layout_detail_info_input">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="gstno_data" readonly>
                        </div>

                        <div class="admin_layout_detail_info_title">
                            이름
                        </div>
                        <div class="admin_layout_detail_info_input" style="width: 400px;">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="gstname_data" readonly>
                        </div>
                    </div>


                    <div v-if="rmno_data != 'P-LOUNGE'" class="admin_layout_detail_info_box">
                        <div class="admin_layout_detail_info_title" style="border: 1px solid #D8D8D8;">
                            체크인 날짜
                        </div>
                        <div class="admin_layout_detail_info_input">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="checkin_data" readonly>
                        </div>
                        <div class="admin_layout_detail_info_title" >
                            체크아웃 날짜
                        </div>
                        <div class="admin_layout_detail_info_input">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="checkout_data" readonly>
                        </div>
                        <div class="admin_layout_detail_info_title" >
                            키 발급여부
                        </div>
                        <div class="admin_layout_detail_info_input">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="status_data" readonly>
                        </div>

                    </div>


                    <div class="admin_layout_detail_title_box" style="margin-top:50px;">블루투스 통신 정보</div>

                    <div class="admin_layout_detail_info_box">
                        <div class="admin_layout_detail_info_title" style="border: 1px solid #D8D8D8;">
                            기기 이름
                        </div>
                        <div class="admin_layout_detail_info_input">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="lockid_data" readonly>
                        </div>
                    </div>


                    <div class="admin_layout_detail_info_box">
                        <div class="admin_layout_detail_info_title" style="width:100px; border: 1px solid #D8D8D8;">
                            agent ID
                        </div>
                        <div class="admin_layout_detail_info_input" style="width: 130px;">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="agentid_data" readonly>
                        </div>
                        <div class="admin_layout_detail_info_title" style="width:100px;">
                            sys ID
                        </div>
                        <div class="admin_layout_detail_info_input" style="width: 130px;">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="sysid_data" readonly>
                        </div>
                        <div class="admin_layout_detail_info_title" style="width:100px;">
                            rgn ID
                        </div>
                        <div class="admin_layout_detail_info_input" style="width: 130px;">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="rgnid_data" readonly>
                        </div>
                        <div class="admin_layout_detail_info_title" style="width:100px;">
                            subrgn ID
                        </div>
                        <div class="admin_layout_detail_info_input" style="width: 130px;">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="subrgnid_data" readonly>
                        </div>
                    </div>

                    <div class="admin_layout_detail_info_box">
                        <div class="admin_layout_detail_info_title" style="width:100px; border: 1px solid #D8D8D8;">
                            DEV ID
                        </div>
                        <div class="admin_layout_detail_info_input" style="width: 130px;">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="devid_data" readonly>
                        </div>
                        <div class="admin_layout_detail_info_title" style="width:160px;">
                            BLE LOCALNAME
                        </div>
                        <div class="admin_layout_detail_info_input" style="width: 190px;">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="blelocalname_data" readonly>
                        </div>
                        <div class="admin_layout_detail_info_title" style="width:130px;">
                            Mac Address
                        </div>
                        <div class="admin_layout_detail_info_input" style="width: 190px;">
                            <input class="admin_layout_detail_input_number_ch" type="text"  v-model="macaddress_data" readonly>
                        </div>

                    </div>


                    <div class="admin_layout_detail_complete_button_box">
                        <span @click="doorlock_modify(this.$route.query.BM_RMNO)" class="admin_layout_detail_complete_button_white" style="margin-left:10px;"> 수정하기 </span>
                        <span @click="doorlock_delete" class="admin_layout_detail_complete_button" style="margin-left:10px;"> 삭제하기 </span>
                    </div>


                </div>

            </div>


        </div>



    </div>
</template>

<script>

    export default {
        components:{

        },
        data(){
            return{
                //일반 데이터
                rmno_data : '',
                doorlock_data : '',
                building_data : '',
                floor_data : '',
                //투숙객 데이터
                gstname_data : '',
                gstno_data : '',
                status_data : '',
                checkin_data : '',
                checkout_data : '',
                //블루트스 데이터
                lockid_data : '',
                agentid_data : '',
                sysid_data : '',
                rgnid_data : '',
                subrgnid_data : '',
                devid_data : '',
                blelocalname_data : '',
                macaddress_data : '',
            }
        },
        async created(){

            // //로그인 세션 검증
            // if(this.$session.get('admin_session') === '' || !this.$session.get('admin_session') ){
            //     alert('로그인 세션이 만료되었습니다. 다시 한번 로그인해주세요');
            //     this.$router.replace('/admin/login');
            // }


          // 로그인 세션 검증
          const adminSession = sessionStorage.getItem('admin_session');
          const expirationTime = sessionStorage.getItem('expiration_time');
          const now = new Date().getTime();

          // 세션이 없거나, 만료 시간이 지난 경우
          if (!adminSession || now > expirationTime) {
            alert('로그인 세션이 만료되었습니다. 다시 한번 로그인해주세요.');
            sessionStorage.removeItem('admin_session'); // 세션 제거
            sessionStorage.removeItem('expiration_time'); // 만료 시간 제거
            this.$router.replace('/admin/login'); // 로그인 페이지로 리다이렉트
          }


            /**************** 시간 설정 **********/
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);
            var hour = ("0" + date.getHours()).slice(-2);
            var minute = ("0" + date.getMinutes()).slice(-2);
            var second = ("0" + date.getSeconds()).slice(-2);
            var NOW_TIME = year + month + day + hour + minute + second;
            this.now_time = NOW_TIME;
        },

        async mounted(){


            //도어락 데이터 불러오기
            this.doorlock_info();
            ///selectjs 함수 호출
            this.select_box_js();

        },
        methods:{

            //도어락 데이터 불러오기
            async doorlock_info() {

                try{
                    const res = await this.axios({
                        method: "POST",
                        url: `${process.env.VUE_APP_API_SERVER}/api/admin/doorlock_info`,
                        headers: {
                            "Content-Type" : "application/json",
                            // "Authorization" : `Bearer ${this.$session.get('admin_session')}`
                            'Authorization': `Bearer ${sessionStorage.getItem('admin_session')}`

                        },
                        data: { rmno : this.$route.query.BM_RMNO}
                    });


                    const data = await res.data;

                    if(data.status === "SUCCESS"){

                        if(data.result.length === 0){
                            alert('해당 도어락은 존재하지 않는 도어락입니다')
                            this.$router.replace('/admin/doorlock_list');
                        }else{
                            //일반 데이터 세팅
                            this.rmno_data = data.result[0].BM_RMNO;
                            if(data.result[0].BM_DOORLOCK === "ROOM"){
                                this.doorlock_data = '객실';
                            }else{
                                this.doorlock_data = '공용도어';
                            }
                            if(data.result[0].BM_BUILDING === "ORG"){
                                this.building_data= '본관'
                            }else{
                                this.building_data= '신관'
                            }
                            if(data.result[0].BM_RMNO === 'P-LOUNGE'){
                                this.floor_data = "-";
                            }else{
                                this.floor_data = data.result[0].BM_FLOOR + "층";
                            }
                            //투숙객 데이터 세팅 (예약번호가 null값이거나, 현재 시각보다 체크아웃 날짜가 작을 경우)
                            if(data.result[0].BM_GSTNO===null || data.result[0].BM_DEPARTTIME < this.now_time ){
                                this.gstname_data = '-';
                                this.gstno_data = '-';
                                this.status_data = '발급 전';
                                this.checkin_data = '-';
                                this.checkout_data = '-';
                            }else{
                                this.gstname_data = data.result[0].BM_GSTNAME;
                                this.gstno_data = data.result[0].BM_GSTNO;
                                this.status_data = '발급완료';
                                this.checkin_data = data.result[0].BM_ARRIVTIME.substr(0, 4) + '-' + data.result[0].BM_ARRIVTIME.substr(4, 2) + '-' + data.result[0].BM_ARRIVTIME.substr(6, 2);
                                this.checkout_data = data.result[0].BM_DEPARTTIME.substr(0, 4) + '-' + data.result[0].BM_DEPARTTIME.substr(4, 2) + '-' + data.result[0].BM_DEPARTTIME.substr(6, 2);
                            }
                            //블루투스 데이터 세팅
                            this.lockid_data = data.result[0].BM_LOCKID;
                            this.agentid_data = data.result[0].WY_AGENTID;
                            this.sysid_data = data.result[0].WY_SYSID;
                            this.rgnid_data = data.result[0].WY_REGIONID;
                            this.subrgnid_data = data.result[0].WY_SUBRGNID;
                            this.devid_data = data.result[0].WY_DEVID;
                            this.blelocalname_data = data.result[0].WY_BLELOCALNAME;
                            this.macaddress_data = data.result[0].WY_MACID;
                        }
                    }else{
                        alert('도어락 데이터 조회에 실패했습니다.\n잠시 후에 다시 시도해주세요')
                    }

                }catch (e) {
                    console.log(e);

                }

            },

            //도어락 데이터 삭제하기
            async doorlock_delete(){

                var delete_confirm = confirm("정말로 삭제하시겠습니까? \n 해당 도어락 삭제 시, 해당 객실의 모바일키 오픈은 불가합니다");

                if(delete_confirm == true){

                    try{

                        const res = await this.axios({
                            method: "POST",
                            url: `${process.env.VUE_APP_API_SERVER}/api/admin/doorlock_delete`,
                            headers: {
                                "Content-Type": "application/json",
                                // 'Authorization': `Bearer ${this.$session.get('admin_session')}`
                                'Authorization': `Bearer ${sessionStorage.getItem('admin_session')}`
                            },
                            data: { rmno : this.$route.query.BM_RMNO}
                        });

                        const data = await res.data;

                        if(data.status === "SUCCESS"){
                            alert('해당 도어락이 삭제되었습니다.\n해당 객실의 모바일키 오픈은 불가합니다')
                            this.$router.push('/admin/doorlock_list');
                        }else{
                            alert('해당 도어락 삭제에 실패했습니다.\n잠시 후에 다시 시도해주세요')
                        }


                    }catch (e) {
                        console.log(e);

                    }
                }
            },

            //도어락 데이터 수정하기
            doorlock_modify(BM_RMNO){
                this.$router.push('/admin/modify?BM_RMNO='+BM_RMNO);
            },

            //로그아웃
            logout() {

                var logout_confirm = confirm("정말로 로그아웃 하시겠습니까?");

                if(logout_confirm == true){
                    //토큰, 세션 삭제 후 로그인 페이지로 이동
                    // this.$session.set('admin_session', '');
                    sessionStorage.removeItem('admin_session'); // 세션 제거
                    sessionStorage.removeItem('expiration_time'); // 만료 시간 제거
                    this.$router.replace('/admin/login');
                }
            },

            //페이지 이동 함수
            doorlock_list(){
                this.$router.push('/admin/doorlock_list');
            },
            register() {
                this.$router.push('/admin/register');
            },
            key_issue_list() {
                this.$router.push('/admin/key_issue_list');
            },
            key_usage_list() {
                this.$router.push('/admin/key_usage_list');
            },

            //selectjs 함수
            select_box_js() {
                var x, i, j, l, ll, selElmnt, a, b, c;
                /* Look for any elements with the class "admin-select": */
                x = document.getElementsByClassName("admin-select");
                l = x.length;
                for (i = 0; i < l; i++) {
                    selElmnt = x[i].getElementsByTagName("select")[0];
                    ll = selElmnt.length;
                    /* For each element, create a new DIV that will act as the selected item: */
                    if(document.getElementsByClassName("admin-select-selected")[i]){
                        document.getElementsByClassName("admin-select-selected")[i].remove();
                    }
                    if(document.getElementsByClassName("admin-select-items admin-select-hide")[i]){
                        document.getElementsByClassName("admin-select-items admin-select-hide")[i].remove();
                    }
                    a = document.createElement("DIV");
                    a.setAttribute("class", "admin-select-selected");
                    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
                    x[i].appendChild(a);
                    /* For each element, create a new DIV that will contain the option list: */
                    b = document.createElement("DIV");
                    b.setAttribute("class", "admin-select-items admin-select-hide");
                    for (j = 0; j < ll; j++) {
                        /* For each option in the original select element,
                        create a new DIV that will act as an option item: */
                        c = document.createElement("DIV");
                        c.innerHTML = selElmnt.options[j].innerHTML;
                        c.addEventListener("click", function (e) {
                            /* When an item is clicked, update the original select box,
                            and the selected item: */
                            e.stopPropagation();
                            var y, i, k, s, h, sl, yl;
                            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                            sl = s.length;
                            h = this.parentNode.previousSibling;
                            for (i = 0; i < sl; i++) {
                                if (s.options[i].innerHTML == this.innerHTML) {
                                    s.selectedIndex = i;
                                    h.innerHTML = this.innerHTML;
                                    y = this.parentNode.getElementsByClassName("same-as-selected");
                                    yl = y.length;
                                    for (k = 0; k < yl; k++) {
                                        y[k].removeAttribute("class");
                                    }
                                    this.setAttribute("class", "same-as-selected");
                                    break;
                                }
                            }
                            h.click();
                        });
                        b.appendChild(c);
                    }
                    x[i].appendChild(b);
                    a.addEventListener("click", function (e) {
                        //console.log('클릭')
                        /* When the select box is clicked, close any other select boxes,
                        and open/close the current select box: */
                        e.stopPropagation();
                        closeAllSelect(this);
                        this.nextSibling.classList.toggle("admin-select-hide");
                        this.classList.toggle("select-arrow-active");
                    });
                }

                function closeAllSelect(elmnt) {
                    /* A function that will close all select boxes in the document,
                    except the current select box: */
                    var x, y, i, xl, yl, arrNo = [];
                    x = document.getElementsByClassName("admin-select-items");
                    y = document.getElementsByClassName("admin-select-selected");
                    xl = x.length;
                    yl = y.length;
                    for (i = 0; i < yl; i++) {
                        if (elmnt == y[i]) {
                            arrNo.push(i)
                        } else {
                            y[i].classList.remove("select-arrow-active");
                        }
                    }
                    for (i = 0; i < xl; i++) {
                        if (arrNo.indexOf(i)) {
                            x[i].classList.add("admin-select-hide");
                        }
                    }
                }
                /* If the user clicks anywhere outside the select box,
                then close all select boxes: */
                document.addEventListener("click", closeAllSelect);
            }
        }
    }
</script>


<style>
    .admin-select-selected {background-color: white;border: none;}
    .admin_layout_detail_input_number_ch{width: 100%; height: 48px; line-height:52px; padding: 0px 15px; font-size: 16px; font-family: AppleM; border: none;outline:none;border-radius: 0px 6px 6px 0px;}
    .admin_layout_detail_box{display: flex;flex-direction: column;margin-top:40px; padding: 32px 65px; width: 100%;  background-color:white;border-radius: 6px;border: 1px solid #EDEDED;}
    .admin_layout_detail_title_box{font-size: 24px; font-family: AppleB}
    .admin_layout_detail_info_box{display:flex; flex-direction:row; margin-top: 24px; width: 100%; height: 58px;}
    .admin_layout_detail_info_title{display:flex; justify-content:center;width: 130px; height: 54px; line-height: 52px;background-color: #EDEDED; border-top: 1px solid #D8D8D8; border-right: 1px solid #D8D8D8; border-bottom: 1px solid #D8D8D8; color: #9C836A; font-family: AppleM; font-size: 16px;}
    .admin_layout_detail_info_input{display:flex; justify-content:center;width: 180px; height: 54px; line-height: 52px;background-color: white; border-top: 1px solid #D8D8D8; border-right: 1px solid #D8D8D8; border-bottom: 1px solid #D8D8D8;  font-family: AppleM; font-size: 16px;}
    .admin_layout_detail_info_select{display:flex; justify-content:center;width: 180px; height: 52px; background-color: white; border-top: 1px solid #D8D8D8; border-right: 1px solid #D8D8D8; border-bottom: 1px solid #D8D8D8;  font-family: AppleM; font-size: 16px;}
    .admin_layout_detail_info_button{display:flex; justify-content:center;margin-left:30px; width: 120px; height: 50px; line-height: 50px; color: white; font-family: AppleM; font-size: 14px; background-color: #4D4843; cursor: pointer;}
    .admin_layout_detail_info_button{display:flex; justify-content:center;margin-left:30px; width: 120px; height: 50px; line-height: 50px; color: white; font-family: AppleM; font-size: 14px; background-color: #4D4843; cursor: pointer;}
    .admin_layout_detail_complete_button_box{display:flex; flex-direction:row-reverse;margin-top:100px;width: 100%; }
    .admin_layout_detail_complete_button{display:flex; justify-content:center;width: 180px; height: 50px; line-height:50px; background-color: #9C836A; color: white; font-size: 16px; font-family: AppleM;border-radius: 6px; cursor: pointer }
    .admin_layout_detail_complete_button_white{display:flex; justify-content:center;width: 180px; height: 48px; line-height:50px;  border:1px solid #9C836A; background-color: white; color: #9C836A; font-size: 16px; font-family: AppleM;border-radius: 6px; cursor: pointer }
</style>
